import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import servicesStyles from "../styles/pages/services.module.css"

import modelsContent from "../images/models-content.png"
import artistsContent1 from "../images/artists-content-1.png"
import artistsContent2 from "../images/artists-content-2.png"
import speakersContent from "../images/speakers-content.png"
import performersContent1 from "../images/performers-content-1.png"
import performersContent2 from "../images/performers-content-2.png"
import uniqueTalents from "../images/unique-talents.png"
import resourceSpeakers from "../images/resource-speakers.png"
import models from "../images/models.png"
import entertainers from "../images/entertainers.png"
import artists from "../images/artists.png"
import musicians from "../images/musicians.png"
import hosts from "../images/hosts.png"

const ServicesPage = () => (
    <Layout location="services">
        <SEO title="Services" />
        <div className={ servicesStyles.descriptions }>
            <div className={ servicesStyles.title }>
                <div className={ servicesStyles.titleWrapper }>
                    <h1>What we can provide //</h1>
                </div>
            </div>
            <div className={ servicesStyles.description }>
                <div className={ servicesStyles.descriptionWrapper }>
                    <h3>Models</h3>
                    <div className={ servicesStyles.top }>
                        <img src={ modelsContent } />
                    </div>
                    <div className={ servicesStyles.bottom }>
                        <p>Charm, presence, and undeniably broad appeal to every imaginable sector. Trust us, we have what your world has been waiting for. Flair! Talent Bureau proudly presents both promising and finest ramp and fashionn models in the country. Geared to turn heads and ever ready for the prime time. They're poised to take the stage by storm.</p>
                    </div>
                </div>
            </div>
            <div className={ servicesStyles.description }>
                <div className={ servicesStyles.descriptionWrapper }>
                    <h3>Artists</h3>
                    <section>
                        <div className={ servicesStyles.left }>
                            <p>Prepare to be surprised and always be left wanting more. Flair! Talent Bureau prides itself of continuously discovering only the freshest faces, the brightest talents and the most versatile artists from every generation,</p>
                        </div>
                        <div className={ servicesStyles.right }>
                            <img src={ artistsContent1 } />
                        </div>
                    </section>
                    <section>
                        <div className={ servicesStyles.left }>
                            <img src={ artistsContent2 } alt=""/>
                        </div>
                        <div className={ servicesStyles.right }>
                            <p>crossing the different spectrums of any market you can think of. At Flair!, a performance isn't an appointment, it's an absolute bliss.</p>
                        </div>
                    </section>
                </div>
            </div>
            <div className={ servicesStyles.description }>
                <div className={ servicesStyles.descriptionWrapper }>
                    <h3>Speakers</h3>
                    <div className={ servicesStyles.top }>
                        <p>Public speakers influence countless lives in a single hour motivating untold numbers through words shaped and refined by their dreams and aspirations. With the demand for inspiring presenters increasing across the country, Flair! Talent Bureau prides itself of being able to deliver bankable, sophisticated yet remarkably down-to-earth worthy speakers who bring about change and realizations in simply the most effortless and timeless way.</p>
                    </div>
                    <div className={ servicesStyles.bottom }>
                        <img src={ speakersContent } />
                    </div>
                </div>
            </div>
            <div className={ servicesStyles.description }>
                <div className={ servicesStyles.descriptionWrapper }>
                    <h3>Performers</h3>
                    <section>
                        <div className={ servicesStyles.left }>
                            <p>Only the extraordinary are remembered. And at Flair!, it is made available for you. We thrive on creating, managing, and delivering ensembles: uniquely gifted, immensely appealing talents who can be counted on to deliveer anything you could ever need, with swagger and style to boot.</p>
                        </div>
                        <div className={ servicesStyles.right }>
                            <img src={ performersContent1 } />
                        </div>
                    </section>
                    <section>
                        <div className={ servicesStyles.left }>
                            <img src={ performersContent2 } />
                        </div>
                        <div className={ servicesStyles.right }>
                            <p>Singers. Rock Bands. Dancers who stomp the yard. We have them all. And we have them all for you.</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div className={ servicesStyles.listOfServices }>
            <div className={ servicesStyles.title }>
                <div className={ servicesStyles.titleWrapper }>
                    <h1>What you can expect //</h1>
                </div>
            </div>
            <div className={ servicesStyles.services }>
                <div className={ servicesStyles.column }>
                    <div className={ servicesStyles.service }>
                        <img src={ uniqueTalents } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Unique Talents</h3>
                        </div>
                    </div>
                    <div className={ servicesStyles.service }>
                        <img src={ resourceSpeakers } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Resource Speakers</h3>
                        </div>
                    </div>
                    <div className={ servicesStyles.service }>
                        <img src={ models } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Models</h3>
                        </div>
                    </div>
                    <div className={ servicesStyles.service }>
                        <img src={ entertainers } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Entertainers</h3>
                        </div>
                    </div>
                </div>
                <div className={ servicesStyles.column }>
                    <div className={ servicesStyles.service }>
                        <img src={ artists } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Artists</h3>
                        </div>
                    </div>
                    <div className={ servicesStyles.service }>
                        <img src={ musicians } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Musicians</h3>
                        </div>
                    </div>
                    <div className={ servicesStyles.service }>
                        <img src={ hosts } />
                        <div className={ servicesStyles.serviceText}>
                            <h3>Hosts</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ServicesPage